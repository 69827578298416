// import React from "react";

export const addImagePath = (img) => {
    let data = img !== null && img.split('/')[0]
    if (data === "uploads") {
        return process.env.API_IMAGE_URL + img
    }
    else {
        return img
    }
}