import React from 'react';
import { Modal } from 'react-bootstrap';
import './popup.css'
import "react-toastify/dist/ReactToastify.css"
import AvatarImage from "../Avatar/avatarImage"

export default function AgentShowProfile(props) {
    const { showAgent, toggleAgent, detail } = props;

    const hanldeImage = (img) => {
        let data = img !== null && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }

    return (
        <div>
            <Modal show={showAgent} onHide={toggleAgent} className='special_modal ModalDiv'>
                <div className="PopUpBorderDiv">
                    <Modal.Header closeButton className="team-popup-margin teampopup-title" >
                        <Modal.Title className='form-field-label form-label-team '>Agent Details</Modal.Title>
                    </Modal.Header>
                    <hr />
                    <Modal.Body className="team-popup-margin pb-0 "  >
                        <div className='d-flex justify-content-center'>
                            <div className="col-md-3" onClick={toggleAgent} aria-hidden>
                                {detail !== undefined && detail !== null && detail.img ?
                                    (detail.img.split("/")[0] === "uploads" ?
                                        <img src={hanldeImage(detail !== undefined && detail !== null && detail.img)} alt='tour2' className="tour" width={150} height={150} style={{ borderRadius: "75px",objectFit:"cover" }} crossOrigin="anonymous" />
                                        : <img src={hanldeImage(detail !== undefined && detail !== null && detail.img)} alt='tour2' className="tour" width={150} height={150} style={{ borderRadius: "75px",objectFit:"cover" }} />
                                    ) : <div style={{ width: '75px', height: '75px', position: 'relative', marginLeft: "25px" }} ><AvatarImage fontSize={'38px'} data={detail !== undefined && detail !== null && detail.name ? detail !== undefined && detail !== null && detail.name : ''} /></div>
                                }
                            </div>
                        </div>
                        <div className='mt-3 d-flex'>
                            <p style={{ fontFamily: "DejaVuSansBold" }}>Name</p>
                            <p style={{ paddingLeft: "80px" }}>{detail !== undefined && detail !== null && detail.name}</p>
                        </div>
                        <div className='d-flex '>
                            <p style={{ fontFamily: "DejaVuSansBold" }}>Email</p>
                            <p style={{ paddingLeft: "82px" }}>{detail !== undefined && detail !== null && detail.email}</p>
                        </div>
                        {detail !== undefined && detail !== null && detail.phone_number !== "" && detail.phone_number !== null &&
                            <div className='d-flex' >
                                <p style={{ fontFamily: "DejaVuSansBold" }}>Number</p>
                                <p style={{ paddingLeft: "58px" }}>{detail !== undefined && detail !== null && detail.phone_number}</p>
                            </div>
                        }
                        {detail !== undefined && detail !== null && detail.address !== "" && detail.address !== null &&
                            <div className='d-flex' >
                                <p style={{ fontFamily: "DejaVuSansBold" }}>Address</p>
                                <p style={{ paddingLeft: "58px" }}>{detail !== undefined && detail !== null && detail.address}</p>
                            </div>}
                        {detail !== undefined && detail !== null && detail.address_city !== "" && detail.address_city !== null &&
                            <div className='d-flex' >
                                <p style={{ fontFamily: "DejaVuSansBold" }}>City</p>
                                <p style={{ paddingLeft: "95px" }}>{detail !== undefined && detail !== null && detail.address_city}</p>
                            </div>}
                        {detail !== undefined && detail !== null && detail.license_number !== "" && detail.license_number !== null &&
                            <div className='d-flex' >
                                <p style={{ fontFamily: "DejaVuSansBold" }}>License No</p>
                                <p style={{ paddingLeft: "34px" }}>{detail !== undefined && detail !== null && detail.license_number}</p>
                            </div>}
                        {detail !== undefined && detail !== null && detail.office_name !== "" && detail.office_name !== null &&
                            <div className='d-flex' >
                                <p style={{ fontFamily: "DejaVuSansBold" }}>Office Name</p>
                                <p style={{ paddingLeft: "22px" }}>{detail !== undefined && detail !== null && detail.office_name}</p>
                            </div>}
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}
