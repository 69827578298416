import { StreamChat } from "stream-chat";
import { addImagePath } from "./addImagePath";

export const createGroupChat=async(userDetails,chatId,chatName)=>{
    let user = {
        id: userDetails._id,
        name: userDetails.name,
        image: addImagePath(userDetails.img !== null ? userDetails.img : "")
    }
    const chatClient = StreamChat.getInstance(process.env.STREAM_CHAT_APIKEY);
    await chatClient.connectUser(user, chatClient.devToken(user.id, user.image));
    const channel = await chatClient.channel('messaging',chatId, {
        name:chatName
    })
    await channel.watch();
    return {channel:channel,chatClient:chatClient}
}