import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import *as style1 from '../../pages/dashboard/detail.module.css';
import './popup.css'
// import * as Yup from 'yup';
import { claimPropertyList, sendRequestToAgent } from "../../components/Api/ListingApi";
import { useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from "react-google-autocomplete";
import ToastPopup from './ToastPopup';
import CurrencyInput from 'react-currency-input-field';
import { formatPrice } from '../../common/GenericFunction';

export default function SelectAgentPopup(props) {
  const { show, toggleShow } = props;
  const [propertyId, setPropertyId] = useState(null)
  const [showSelectOption, setShowSelectOption] = useState(false)
  const [open, setOpen] = useState('')
  const [place, setPlace] = useState('')
  const [isPlace, setIsPlace] = useState(false)
  const [success, setSucess] = useState(false);
  const [messages, setMessages] = useState("")
  const toggleSuccess = () => setSucess(p => !p);

  useEffect(() => {
    setShowSelectOption(false)
  }, [])

  const showDetail = (e) => {
    setShowSelectOption(true)
    if (e.target.value === 'buy') {
      setOpen('buy')
      setFormFields({
        budget: "",
        prequalified: "",
        bed: "",
        bath: "",
        message: "",
      })
      setprequalified("")
      setbudget("")
      setbath("")
      setbed("")
    }
    else if (e.target.value === 'sell') {
      setOpen('sell')
    }
  };
  const showId = (id) => {
    setPropertyId(id)
  }
  const [formFields, setFormFields] = useState({
    budget: "",
    prequalified: "",
    bed: "",
    bath: "",
    message: "",
  })
  const [listingDatas, setListingDatas] = useState([]);
  // console.log("listingDatas", listingDatas)
  // const [claimDatas, setClaimDatas] = useState([]);
  const userDetails = useSelector(state => state.user.userDetail);
  useEffect(() => {
    async function fetchData() {
      await listingApis();
    } fetchData()

  }, [])
  const listingApis = async () => {

    await claimPropertyList({ id: userDetails?._id ? userDetails._id : "", status: 'Approve' })
      .then(async (res) => {
        if (res.status === 200) {
          let claimData = res.data.data;
          if (claimData.length > 0) {
            // setClaimDatas(claimData);

            setListingDatas(claimData);
          } else {

          }
        }
      }).catch((error) => {
        console.log(error)
      })
  }

  const handleSeller = async () => {
    if (propertyId !== '') {
      let userId = localStorage.getItem('userId')
      let data = {
        user_id: userId,
        agent_id: [props.agentId],
        type: 'sell',
        property_id: propertyId
      }
      await sendRequestToAgent(data).then((res) => {
        setMessages(res.data.message)
        if (res.status === 200) {
          toggleSuccess()
        }
        else {
          toggleSuccess()
        }
      }).catch((error) => {
        console.log(error)
      })
      toggleShow()
      setPropertyId(null)
      setShowSelectOption(false)
    }
  }
  const [prequalified, setprequalified] = useState("")
  const [budget, setbudget] = useState("")
  const [bath, setbath] = useState("")
  const [bed, setbed] = useState("")

  const errorHandling = () => {
    if (place === "") {
      setprequalified("Please Enter Address")
    }
    if (formFields.bed === "") {
      setbed("Please Enter correct value")
    }
    if (formFields.bath === "") {
      setbath("Please Enter correct value")
    }
    if (formFields.budget === "") {
      setbudget("Please Enter correct value")
    }
    if (formFields.bed !== "" && formFields.bath !== "" && formFields.budget !== "") {
      handleData()
    }
  }
  const handleData = async () => {

    if (place === '') {
      setIsPlace(true)


    }
    else {
      let userId = localStorage.getItem('userId')

      let data = {
        area: place,
        user_id: userId,
        agent_id: [props.agentId],
        type: 'buy',
        pre_qualified: formFields.prequalified === '' ? 1 : formFields.prequalified,
        beds: formFields.bed,
        baths: formFields.bath,
        message: formFields.message,
        budget: formFields.budget
      }


      await sendRequestToAgent(data).then((res) => {

        setMessages(res.data.message)
        if (res.status === 200) {
          toggleSuccess()

        }
        else {
          toggleSuccess()

        }
      }).catch((error) => {
        console.log(error)
      })
      toggleShow()
      setShowSelectOption(false)
    }
  }
  const hanldeImage = img => {
    let data = img !== null && img.split("/")[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    } else {
      return img
    }
  }
  const handleChange = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value })
  }
  const handlecurrency2 = (value, name) => {
    setFormFields({ ...formFields, [name]: value })
  }
  return (
    <div>
      <ToastContainer />
      <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
      <Modal show={show} onHide={() => { toggleShow(); setShowSelectOption(false) }} className='ModalDiv'>
        <div className="PopUpBorderDiv">
          <Modal.Header closeButton className='justify-content-center'>
            <Modal.Title>Sending request to agent</Modal.Title>
          </Modal.Header>
          <Modal.Body className="team-popup-margin pb-0" >


            <div className='row justify-content-center' style={{ marginBottom: !showSelectOption && '30px' }}>
              <p className={style1.agentText}>What are you looking to do</p>
              <select name="createGroup" className={style1.selectdet} aria-label="--Select--" onChange={(e) => showDetail(e)} >
                <option>--Select--</option>
                <option value="buy">Buy</option>
                <option value="sell">Sell</option>
              </select>
              <p className='error-msg'></p>

            </div>
            {open === 'buy' && showSelectOption ?

              <div className='mb-3'>
                <div className='d-flex'>
                  <div className='w-100 p-2 popUpGooglePlace'>
                    <p className='DejaVuSansBold'>Area <span className='error-msg'>*</span></p>
                    <Autocomplete
                      apiKey={process.env.FIREBASE_API_KEY}
                      onPlaceSelected={(place) => {
                        setPlace(place.formatted_address)
                        setIsPlace(false)
                      }}
                      options={{
                        types: ["geocode", "establishment"],
                        componentRestrictions: { country: "us" },
                      }}
                      onFocus={() => setprequalified("")}
                    />
                    <p className='text-danger mb-0'>{prequalified}</p>
                  </div>
                </div>

                <div className='d-flex'>
                  <div className='w-50 p-2 '>
                    <p className='DejaVuSansBold'>Budget <span className='error-msg'>*</span></p>
                    <div className='position-relative'>
                      <CurrencyInput
                        prefix="$"

                        name="budget"
                        className='w-100 form-control'
                        value={formFields.budget}
                        maxLength={15}
                        onValueChange={(value, name) => {
                          handlecurrency2(value, name);
                        }}
                        onFocus={() => setbudget("")}

                      ></CurrencyInput>
                    </div>
                    <p className='text-danger mb-0'>{budget}</p>
                  </div>
                  <div className='w-50 p-2'>
                    <p className='DejaVuSansBold'>Prequalified </p>
                    <div className='position-relative'>
                      <CurrencyInput
                        prefix="$"

                        name="prequalified"
                        className='w-100 form-control'
                        value={formFields.prequalified}
                        maxLength={15}
                        onValueChange={(value, name) => {
                          handlecurrency2(value, name);
                        }}
                      // onFocus={() => setprequalified("")}
                      ></CurrencyInput>
                    </div>
                    {/* <p className='text-danger mb-0'>{prequalified}</p> */}
                  </div>
                </div>

                <div className='d-flex'>
                  <div className='w-50 p-2'>
                    <p className='DejaVuSansBold'>Bath <span className='error-msg'>*</span></p>
                    <input type="number" className='w-100  form-control' name="bath" onChange={handleChange} onFocus={() => setbath("")} value={formFields.bath} />
                    <p className='text-danger mb-0'>{bath}</p>
                  </div>

                  <div className='w-50 p-2'>
                    <p className='DejaVuSansBold'>Bed <span className='error-msg'>*</span></p>
                    <input type="number" className='w-100 form-control' name="bed" onChange={handleChange} onFocus={() => setbed("")} value={formFields.bed} />
                    <p className='text-danger mb-0'>{bed}</p>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='w-100 p-2'>
                    <p className='DejaVuSansBold'>Message</p>
                    <textarea className="form-control w-100" name="message" onChange={handleChange} value={formFields.message} />
                  </div>
                </div>
                <div className='d-flex justify-content-center p-2'>
                  <Button variant="primary" type='submit' className='px-4' onClick={() => errorHandling()} >
                    Submit
                  </Button>

                </div>
              </div>
              :
              <>
                {open === 'sell' && showSelectOption &&
                  <div className='d-flex flex-column'>
                    {listingDatas.length !== 0 ?
                      <div className='h-300 overflow-auto cursor'>
                        {listingDatas.map((data, i) => (
                          <div className='d-flex p-2' style={{ border: propertyId === data.property_id._id ? '5px solid #86C5F6' : 'none' }} key={i} onClick={() => showId(data.property_id._id)} aria-hidden>
                            <div className='w-25'>

                              {(data.property_id.thumbnail_image !== null && data.property_id.thumbnail_image !== undefined) ?
                                <>
                                  {data.property_id.thumbnail_image !== null && data.property_id.thumbnail_image !== undefined && data.property_id.thumbnail_image.includes('mls') ? <img src={data.property_id.thumbnail_image !== undefined && hanldeImage(data.property_id.thumbnail_image)} alt="EditGroup1 " width="100%" height="100%" /> :
                                    <img src={data.property_id.thumbnail_image !== undefined && hanldeImage(data.property_id.thumbnail_image)} alt="EditGroup1 " crossOrigin='anonymous' width="100%" height="100%" />}</> :
                                <>
                                  {data?.property_id?.location ?
                                    <img src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${data.property_id.address.replaceAll("#", "")}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`} alt="mask192" width="100%" height="100%" />
                                    :
                                    <>{data.property_id.property_latitude !== null && data.property_id.property_longitude !== null &&
                                      <img src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${data.property_id.Full}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`} alt="mask192" width="100%" height="100%" />
                                    }</>}
                                </>}
                            </div>

                            <div className='px-3 w-75'>
                              <p className='mb-1 DejaVuSansBold'>{data?.property_id?.address ? data.property_id.address : ""}</p>
                              <p className='SFPro-regular'>Price: ${formatPrice(data?.property_id?.price ? data.property_id.price.$numberDecimal : "")}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                      :
                      <p className="text-center">No Records Found</p>
                    }
                    <Button variant="primary" onClick={handleSeller} className='px-4 my-3 mx-auto w-25'>
                      Submit
                    </Button>
                  </div>
                }
              </>
            }

          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}
